// Generated by Framer (c2f6d5d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["m7bVC82SC", "smexH2EJi", "P8qo3EXs4"];

const variantClassNames = {m7bVC82SC: "framer-v-1wiuigz", P8qo3EXs4: "framer-v-z9v1zj", smexH2EJi: "framer-v-10srgxa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "m7bVC82SC", "Tab 2": "smexH2EJi", "Tab 3": "P8qo3EXs4"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "m7bVC82SC", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "m7bVC82SC", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap104g178 = activeVariantCallback(async (...args) => {
setVariant("m7bVC82SC")
})

const tap1sj7jtc = activeVariantCallback(async (...args) => {
setVariant("smexH2EJi")
})

const tap1cycopp = activeVariantCallback(async (...args) => {
setVariant("P8qo3EXs4")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sfG1Y", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1wiuigz", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"m7bVC82SC"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({P8qo3EXs4: {"data-framer-name": "Tab 3"}, smexH2EJi: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-xit9ks-container"} layoutDependency={layoutDependency} layoutId={"mJ4CkT23p-container"} transition={transition}><Tab height={"100%"} id={"mJ4CkT23p"} layoutId={"mJ4CkT23p"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({P8qo3EXs4: {tap: tap104g178, variant: "ym0P2kopY"}, smexH2EJi: {tap: tap104g178, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-1unmtuz-container"} layoutDependency={layoutDependency} layoutId={"ooZ4vTITk-container"} transition={transition}><Tab height={"100%"} id={"ooZ4vTITk"} layoutId={"ooZ4vTITk"} tap={tap1sj7jtc} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({smexH2EJi: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-18o8vd3-container"} layoutDependency={layoutDependency} layoutId={"LnywYFnn6-container"} transition={transition}><Tab height={"100%"} id={"LnywYFnn6"} layoutId={"LnywYFnn6"} tap={tap1cycopp} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({P8qo3EXs4: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sfG1Y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sfG1Y .framer-1pefr0x { display: block; }", ".framer-sfG1Y .framer-1wiuigz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-sfG1Y .framer-xit9ks-container, .framer-sfG1Y .framer-1unmtuz-container, .framer-sfG1Y .framer-18o8vd3-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sfG1Y .framer-1wiuigz { gap: 0px; } .framer-sfG1Y .framer-1wiuigz > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-sfG1Y .framer-1wiuigz > :first-child { margin-left: 0px; } .framer-sfG1Y .framer-1wiuigz > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"smexH2EJi":{"layout":["auto","auto"]},"P8qo3EXs4":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramerLNx37rSqF: React.ComponentType<Props> = withCSS(Component, css, "framer-sfG1Y") as typeof Component;
export default FramerLNx37rSqF;

FramerLNx37rSqF.displayName = "Elements/Tabs";

FramerLNx37rSqF.defaultProps = {height: 49, width: 275.5};

addPropertyControls(FramerLNx37rSqF, {variant: {options: ["m7bVC82SC", "smexH2EJi", "P8qo3EXs4"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramerLNx37rSqF, [...TabFonts])